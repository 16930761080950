















































import Vue from 'vue';
import { mapMutations, mapState } from 'vuex';
import lsService from '@/services/localStorage';

export default Vue.extend({
  name: 'SettingsDrawer',
  data() {
    return {
      language: '',
      languages: [
        {
          value: 'en',
          label: 'english',
        },
        {
          value: 'de',
          label: 'deutschland',
        },
      ],
    };
  },
  computed: {
    ...mapState('globals', ['userEmail']),
    getLanguage(): string {
      const { label } = this.languages.find(({ value }) => value === this.language) || {
        label: '',
      };
      return label;
    },
  },
  methods: {
    ...mapMutations('globals', ['LOGOUT']),
    ...mapMutations('orders', ['CLEAR_ORDERS']),
    logout(): void {
      lsService.removeItem('savedTemplate');
      this.LOGOUT();
      this.CLEAR_ORDERS();
      this.$router.replace({ name: 'login' });
    },
  },
  created() {
    const lang = lsService.getItem('locale') || 'en';
    this.language = lang;
  },
  watch: {
    language: {
      handler(val: string) {
        lsService.setLocale(val);
        this.$i18n.locale = val;
      },
      immediate: false,
    },
  },
});
